import { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, message, theme, notification } from 'antd';
import { FFButton, FFNewIcon, FFRow } from '@/uikit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useSystemSettingsStore from '@/stores/systemSettings';
import { SmileOutlined } from '@ant-design/icons';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

let currentVersionHash = '';
const VERSION_UPDATE_NOTIFICATION_KEY = 'new-version-available';

export const AppProvider = ({ children }: AppProviderProps) => {
  const [, contextHolder] = message.useMessage();
  const colorMode = useSystemSettingsStore((state) => state.colorMode);
  const isDarkMode = colorMode === 'dark';
  const [api, notificationContextHolder] = notification.useNotification();

  const reload = async () => {
    // Check if there's a waiting service worker
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      if (registration.waiting) {
        // Add listener for state change
        registration.waiting.addEventListener('statechange', (event) => {
          if ((event.target as ServiceWorker).state === 'activated') {
            window.location.reload();
          }
        });
        // Tell service worker to skip waiting and activate
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      } else {
        // If no service worker is waiting, just reload the page
        window.location.reload();
      }
    } else {
      // Fallback for browsers without service worker support
      window.location.reload();
    }
  };

  const openNotification = () => {
    api.open({
      key: VERSION_UPDATE_NOTIFICATION_KEY,
      message: 'New Version Available',
      description:
        'A new version of FunnelFlux is available. Please update to the latest version to continue using the app.',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      btn: <FFButton onClick={reload}>Update</FFButton>,
      duration: 0,
      placement: 'bottomLeft'
    });
  };

  const checkForNewVersion = () => {
    const checkVersion = async () => {
      try {
        const response = await fetch('/version.json?t=' + new Date().getTime());
        const data = await response.json();
        api.destroy(VERSION_UPDATE_NOTIFICATION_KEY);
        if (!currentVersionHash) {
          currentVersionHash = data.hash;
        } else if (data.hash && data.hash !== currentVersionHash) {
          openNotification();
        }
      } catch (error) {
        console.error('Version check failed:', error);
      }
    };
    checkVersion();
    setInterval(checkVersion, 3 * 60 * 1000);
  };

  useEffect(() => {
    checkForNewVersion();
  }, [])

  return (
    <Suspense
      fallback={
        <FFRow height="100vh" width="100vw" position="fixed" alignItems="center" justifyContent="center">
          <FFNewIcon name="general/app-loading" width={100} height={100} />
        </FFRow>
      }
    >
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: {
                fontFamily: 'Noto Sans, Arial, Helvetica, sans-serif',
                fontSize: 14,
                fontSizeLG: 14,
                fontSizeSM: 14,
                controlHeight: 35,
              },
            }}
          >
            {contextHolder}
            {notificationContextHolder}
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
          </ConfigProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  );
};
