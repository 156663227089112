import useHttp from '@/hooks/http';
import useHttpNotification from '@/hooks/useHttpError';
import { PageGroup } from '@/models/pageGroup';
import { useMutation } from '@tanstack/react-query';

export function usePageGroupCreateMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<PageGroup, Error, PageGroup>({
    mutationKey: ['pageGroup', 'create'],
    mutationFn: async (pageGroup: PageGroup) => {
      const { data } = await post<PageGroup, PageGroup>('v1/page/group/save', pageGroup);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'pageGroup', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'pageGroup');
    },
  });
}

export function usePageGroupUpdateMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<PageGroup, Error, PageGroup>({
    mutationKey: ['pageGroup', 'update'],
    mutationFn: async (pageGroup: PageGroup) => {
      const { data } = await put<PageGroup, PageGroup>('v1/page/group/save', pageGroup);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'pageGroup', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'pageGroup');
    },
  });
}

export function usePageGroupDuplicateMutation(previousIdPageGroup: string) {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<PageGroup, Error, PageGroup>({
    mutationKey: ['pageGroup', 'duplicate'],
    mutationFn: async (pageGroup: PageGroup) => {
      const { data } = await post<PageGroup, PageGroup>(`v1/page/group/duplicate?idPageGroup=${previousIdPageGroup}`, pageGroup);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'duplicated', 'pageGroup', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'duplicated', 'pageGroup');
    },
  });
}

export function usePageGroupDeleteMutation() {
  const { del } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, string>({
    mutationKey: ['pageGroup', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/page/group/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
    onError: (error: any, id) => {
      handleAssetNotification({ idPageGroup: id }, 'deleted', 'pageGroup', error);
    },
    onSuccess: (_, id) => {
      handleAssetNotification({ idPageGroup: id }, 'deleted', 'pageGroup');
    },
  });
}

export function usePageGroupArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, PageGroup>({
    mutationKey: ['pageGroup', 'archive'],
    mutationFn: async (pageGroup: PageGroup) => {
      await put<PageGroup, null>('v1/page/group/save/', pageGroup);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'archived', 'pageGroup', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'archived', 'pageGroup');
    },
  });
}

export function usePageGroupUnArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, PageGroup>({
    mutationKey: ['pageGroup', 'unarchive'],
    mutationFn: async (pageGroup: PageGroup) => {
      await put<PageGroup, null>('v1/page/group/save/', pageGroup);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'unarchived', 'pageGroup', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'unarchived', 'pageGroup');
    },
  });
}
