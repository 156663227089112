import SectionBox from '@/components/SectionBox';
import {
  FFIconButton,
  FFCol,
  FFField,
  FFButton,
  FFRow,
  FFSelect,
  FFSidePanel,
  FFTextArea,
  VisibilityWrapper,
  FFSwitch,
  FFNewIcon,
} from '@/uikit';
import Skeleton from 'react-loading-skeleton';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import { Controller, useForm } from 'react-hook-form';
import { Page } from '@/models/page';
import FFInput from '@/uikit/components/Input';
import useFormStore from '@/stores/forms';
import { redirects } from '@/constants/form';
import CopyButton from '@/components/CopyButton';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FFSelectOption } from '@/uikit/types/select';
import CodeSnippet from '@/components/CodeSnippet';
import { getPageActionLink } from '@/utils/linkJs';
import { OfferSource } from '@/models/offerSource';
import useSystemSettingsStore from '@/stores/systemSettings';
import DataPassing from '@/components/DataPassing';
import { actionNumbers, NO_OFFER_SOURCE } from '@/constants';
import { addUrlSlash, getQueryUrlSeparate } from '@/utils/url';
import { validateUrl } from '@/utils/validation';
import useHttp from '@/hooks/http';
import { generateEntityId } from '@/utils/id';
import { addOrIncrementCopySuffix, trimStringPropertiesInObject } from '@/utils/string';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { useOfferCategoriesListQuery, useOfferQuery } from '@/api/queries/offer';
import { useOfferSourceCategoryListQuery, useOfferSourceQuery } from '@/api/queries/offersource';
import { useOfferDuplicateMutation, useOfferCreateMutation, useOfferUpdateMutation } from '@/api/mutations/offer';
import { withIncrementedVersion } from '@/utils/model';
import useMitt from '@/hooks/mitt';
import './style.scss';

type TabId = 'general' | 'dataPassing' | 'pageActionLinks' | 'jsTracking' | 'conversionTracking' | 'help';

const DEFAULT_OFFER: Page = {
  pageName: '',
  idPage: '',
  pageType: 'offer',
  offerParams: {
    idOfferSource: '',
  },
  queryParams: {},
  baseURL: '',
  notes: '',
  redirectType: '301',
  disableAppendVid: false,
};

const DEFAULT_OFFERSOURCE: OfferSource = {
  queryParams: {},
  offerSourceName: '',
  idOfferSource: '',
  postbackPayout: '',
  postbackSubId: '',
  postbackTxId: '',
};

const getConvertedNoOfferSource = (model: Page): Page => ({
  ...model,
  offerParams: {
    ...model.offerParams,
    idOfferSource:
      model.offerParams?.idOfferSource && model.offerParams?.idOfferSource === NO_OFFER_SOURCE ? '' : model.offerParams!.idOfferSource,
  },
});

const validateOfferPayout = (model: Page) => {
  if (model?.offerParams?.payout === '') {
    model.offerParams.payout = '0';
  }
  return model;
};

const tabs: SidebarTab<TabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Data Passing',
    tabId: 'dataPassing',
    icon: <FFNewIcon name="sidebar-tabs/configure-data-passing" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Page Action Links',
    tabId: 'pageActionLinks',
    icon: <FFNewIcon name="sidebar-tabs/page-action-link" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Javascript Tracking',
    tabId: 'jsTracking',
    icon: <FFNewIcon name="sidebar-tabs/javascript-tracking" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Conversion Tracking',
    tabId: 'conversionTracking',
    icon: <FFNewIcon name="sidebar-tabs/conversion-tracking" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" display="inline-block" type="sidebartab" />,
  },
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TabId;
  defaultFormValues: Page;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

interface RefType {
  onSave: () => void;
}

const Form = forwardRef(({ currentTabId, closeForm, setSubmitLoading, submitLoading, defaultFormValues }: FormProps, ref: Ref<RefType>) => {
  const { get: httpGet } = useHttp();
  const emitter = useMitt();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
    watch,
  } = useForm<Page>({
    defaultValues: defaultFormValues,
  });
  const idOfferSource = watch('offerParams.idOfferSource');
  const baseURL = watch('baseURL');
  const queryParams = watch('queryParams');

  const { data: offerSourceCategoriesList = [] } = useOfferSourceCategoryListQuery();
  const { data: categories = [], refetch: refetchCategories } = useOfferCategoriesListQuery();
  const { data: offerSource = DEFAULT_OFFERSOURCE } = useOfferSourceQuery(idOfferSource);
  const { mutateAsync: createOffer } = useOfferCreateMutation();
  const { mutateAsync: updateOffer } = useOfferUpdateMutation();
  const { mutateAsync: duplicateOffer } = useOfferDuplicateMutation(defaultFormValues?.idPage || '');

  const { domains, userSettings } = useSystemSettingsStore();
  const isDuplication = useFormStore((state) => state.offer.isDuplication);
  const openCategoryForm = useFormStore((state) => state.openCategoryForm);
  const openOfferSourceForm = useFormStore((state) => state.openOfferSourceForm);
  const openVersioningForm = useFormStore((state) => state.openVersioningForm);
  const setVersioningType = useFormStore((state) => state.setVersioningType);

  const [domain, setDomain] = useState<string>(userSettings.defaultCustomDomain);
  const [actionNumber, setActionNumber] = useState<string>('');
  const [inheritedDataPassingHasError, setInheritedDataPassingHasError] = useState(false);
  const [dataPassingHasError, setDataPassingHasError] = useState(false);
  const [latestVersionOfOffer, setLatestVersionOfOffer] = useState<Page>();

  const categorizedOfferSources = useMemo(() => {
    const options: FFSelectOption[] = [];
    offerSourceCategoriesList.forEach((offerSourceCategory) => {
      (offerSourceCategory.offerSources || []).forEach((offerSource) => {
        options.push({
          value: offerSource.idOfferSource,
          label: offerSource.offerSourceName,
          category: offerSourceCategory.categoryName,
        });
      });
    });
    return options;
  }, [offerSourceCategoriesList]);

  const resultingOfferUrl = useMemo(() => {
    const pairs = Object.entries({
      ...(offerSource.queryParams || {}),
      ...(queryParams || {}),
    })
      .reduce((acc: string[], [key, value]: [string, string]) => (!!key && !!value ? [...acc, `${key}=${value}`] : acc), [])
      .join('&');
    const baseUrl = baseURL || '';

    return !!baseUrl ? `${addUrlSlash(baseUrl)}${getQueryUrlSeparate(pairs, baseUrl)}${pairs}` : '';
  }, [offerSource, baseURL, queryParams]);

  const hit = useMemo(() => offerSource?.postbackSubId, [offerSource?.postbackSubId]);
  const tx = useMemo(() => offerSource?.postbackTxId, [offerSource?.postbackTxId]);
  const rev = useMemo(() => offerSource?.postbackPayout, [offerSource?.postbackPayout]);

  useEffect(() => {
    emitter.on('onVersioningConfirm', () => onSave(false, true));
    emitter.on('onCategorySave', () => refetchCategories());
  }, []);

  const onSaveAndCreate = () => {
    onSave(true);
  };

  const onClose = () => {
    emitter.all.clear();
    closeForm();
  };

  const onSave = (isSaveAndCreate = false, isConfirmingVersioning = false) =>
    handleSubmit(async (data) => {
      if (!inheritedDataPassingHasError && !dataPassingHasError) {
        setSubmitLoading(true);
        const onSaveSuccessFn = (offer: Page) => {
          emitter.emit('onOfferSave', offer);
          onClose();
        };

        try {
          const newID = generateEntityId();
          const model = trimStringPropertiesInObject(validateOfferPayout(getConvertedNoOfferSource(data)), ['pageName']);
          const updateModel = withIncrementedVersion(model);
          const duplicateModel: Page = { ...model, meta: { version: 1 } };
          const createModel: Page = { ...model, idPage: newID, meta: { version: 1 } };
          const category = categories.find((category) => category.idCategory === model.idCategory)!;

          if (isConfirmingVersioning) {
            try {
              let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfOffer?.meta?.version);
              await updateOffer(versionedUpdate);
              emitter.emit('onOfferUpdate', versionedUpdate);
              onSaveSuccessFn(versionedUpdate);
            } catch (e) {
              //
            } finally {
              setSubmitLoading(false);
            }
            return;
          } else if (isDuplication) {
            const duplicatedOffer = await duplicateOffer(duplicateModel);
            emitter.emit('onOfferCreate', { data: duplicatedOffer, category });
          } else if (model.idPage) {
            const offer = await httpGet<Page>('v1/page/find/byId', {
              params: { idPage: model.idPage },
            });
            setLatestVersionOfOffer(offer.data);
            if (model.meta?.version !== offer.data.meta?.version) {
              setVersioningType('offer');
              openVersioningForm();
              return;
            } else {
              await updateOffer(updateModel);
              emitter.emit('onOfferUpdate', updateModel);
            }
          } else {
            await createOffer(createModel);
            setValue('idPage', '');
            emitter.emit('onOfferCreate', { data: createModel, category });
          }

          if (isSaveAndCreate) {
            setValue('pageName', addOrIncrementCopySuffix(data.pageName));
          } else {
            onClose();
          }
        } catch (e) {
          //
        } finally {
          setSubmitLoading(false);
        }
      }
    })();

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <>
      <VisibilityWrapper visible={currentTabId === 'general'} beRenderedAlways>
        <SectionBox title="General Settings">
          <FFCol gap="18px" height="max-content">
            <FFRow gap="8px">
              <Controller
                name="pageName"
                control={control}
                rules={{ required: 'Offer Name is required' }}
                render={(opt) => (
                  <FFField label="Offer Name" block>
                    <FFInput
                      value={getValues('pageName')}
                      onChange={opt.field.onChange}
                      error={errors.pageName?.message}
                      placeholder="Offer Name"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="offerParams.idOfferSource"
                control={control}
                render={(opt) => (
                  <FFField label="Source">
                    <FFSelect
                      value={opt.field.value}
                      defaultValueFallback={{
                        label: NO_OFFER_SOURCE,
                        value: NO_OFFER_SOURCE,
                      }}
                      showSearch
                      filterOption
                      sortGroup
                      onSelect={opt.field.onChange}
                      options={categorizedOfferSources}
                      valueGetter={(opt) => opt.value}
                      labelGetter={(opt) => opt.label}
                      placeholder="Offer Source"
                      groupOptions
                      style={{ width: 170 }}
                    />
                    <FFIconButton buttonType="blue" iconName="general/line/add-circle" onClick={openOfferSourceForm} />
                  </FFField>
                )}
              />
              <Controller
                name="idCategory"
                control={control}
                render={(opt) => (
                  <FFField label="Category">
                    <FFSelect
                      value={getValues('idCategory')}
                      onSelect={opt.field.onChange}
                      options={categories}
                      valueGetter={(opt) => opt.idCategory}
                      labelGetter={(opt) => opt.categoryName}
                      placeholder="Category"
                      style={{ width: 170 }}
                    />
                    <FFIconButton buttonType="blue" iconName="general/line/add-circle" onClick={() => openCategoryForm('offer')} />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="baseURL"
                control={control}
                rules={{ required: 'Base Offer URL is required', validate: validateUrl }}
                render={(opt) => (
                  <FFField
                    label="Base Offer URL"
                    block
                    tooltipContent={
                      <>
                        Put your offer page URL here. You can configure appending of extra data (query string) in the{' '}
                        <b>Append Extra Data</b> section. You can still use tokens in this base URL.
                      </>
                    }
                  >
                    <FFInput
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      error={errors.baseURL?.message}
                      placeholder="Offer URL"
                    />
                    <CopyButton value={opt.field.value} />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="redirectType"
                control={control}
                render={(opt) => (
                  <FFField label="Redirect Type">
                    <FFSelect
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      options={redirects}
                      valueGetter={(opt) => opt.value}
                      labelGetter={(opt) => opt.label}
                      placeholder="Redirect Type"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="offerParams.payout"
                control={control}
                render={(opt) => (
                  <FFField label="Offer Payout">
                    <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Offer Payout" />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="notes"
                control={control}
                render={(opt) => (
                  <FFField label="Notes" block>
                    <FFTextArea value={opt.field.value} onChange={opt.field.onChange} rows={4} placeholder="Notes" />
                  </FFField>
                )}
              />
            </FFRow>
            <FFButton type="primary" onClick={onSaveAndCreate} loading={submitLoading}>
              Save & Create New
            </FFButton>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'dataPassing'} beRenderedAlways>
        <FFCol gap="18px" height="max-content">
          <SectionBox title="Configure Data Passing">
            <p>Here you can configure additional data that gets appended to your base page URL.</p>
          </SectionBox>
          <SectionBox title="Inherited From Offer Source">
            <DataPassing
              queryParams={offerSource.queryParams || {}}
              pageName="Data Passing Offers"
              baseURL={baseURL}
              idOfferSource={idOfferSource}
              showErrors={isSubmitted}
              setError={setInheritedDataPassingHasError}
              disableAdd={true}
              disableFields={true}
              disableRemove={true}
              reinitialize={true}
            />
          </SectionBox>
          <SectionBox title="Offer-level Data Passing">
            <FFCol gap="12px">
              <p>
                Here you can configure additional URL parameters that will pass for this offer. If any of these overlap with the offer
                source, the offer-level parameters will take priority.
              </p>
              <DataPassing
                queryParams={queryParams || {}}
                onChange={(queryParams) => setValue('queryParams', queryParams)}
                pageName="Data Passing Offers"
                baseURL={baseURL}
                showErrors={isSubmitted}
                setError={setDataPassingHasError}
              />
            </FFCol>
          </SectionBox>
          <SectionBox>
            <FFField
              label="Resulting Offer URL"
              block
              tooltipContent={
                <>
                  This is the resulting URL from your base URL + query string data configured in the <b>Append Extra Data</b> section. This
                  is the URL FunnelFlux Pro will use when redirecting users to your page.
                </>
              }
            >
              <CodeSnippet data-testid="finalUrl" placeholder="Resulting Offer URL" code={resultingOfferUrl} />
            </FFField>
          </SectionBox>
          <SectionBox title="Advanced Settings">
            <FFCol gap="8px">
              <p>Here you can configure advanced settings for data passing</p>
              <Controller
                name="disableAppendVid"
                control={control}
                render={(opt) => (
                  <FFField block>
                    <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
                      Disable automatic appending of visitor ID on redirect
                    </FFSwitch>
                  </FFField>
                )}
              />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'pageActionLinks'} beRenderedAlways>
        <SectionBox title="Page Action Links">
          <FFCol gap="12px">
            <p>
              Action links are universal click-through URLs that you should use in your pages to send visitors to the next node. You can
              right-click action connections in the funnel builder to get URLs with additional default parameters.
            </p>
            <FFRow gap="8px" marginBottom="18px">
              <FFField label="Select a domain" block>
                <FFSelect
                  options={domains}
                  valueGetter={(opt) => opt.domain}
                  labelGetter={(opt) => opt.domain}
                  value={domain}
                  onSelect={setDomain}
                  placeholder="Select a domain"
                />
              </FFField>
              <FFField label="Action number" block>
                <FFSelect
                  options={actionNumbers}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => opt.label}
                  value={actionNumber}
                  onSelect={setActionNumber}
                  placeholder="Action Number"
                />
              </FFField>
            </FFRow>
            <FFField label="Action URL" block>
              <CodeSnippet
                data-testid="actionURL"
                placeholder="Action URL"
                className="width-full"
                code={getPageActionLink(domain, actionNumber)}
              />
            </FFField>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'jsTracking'} beRenderedAlways>
        <FFCol gap="18px" height="max-content">
          <SectionBox title="Required: Universal JS Tag">
            <FFCol gap="12px">
              <FFField label="Select a domain:" direction="row" block>
                <FFSelect
                  options={domains}
                  valueGetter={(opt) => opt.domain}
                  labelGetter={(opt) => opt.domain}
                  value={domain}
                  onSelect={setDomain}
                  placeholder="Select a domain"
                />
              </FFField>
              <p>
                This script is required for direct linking. Place it just before {' </head> '}. We recommend adding this to all of your
                pages regardless as it improves tracking. This code already includes a page view event.
              </p>
              <CodeSnippet codeType="genericViewFull" domain={domain} />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: View-only Event">
            <FFCol gap="12px">
              <p>
                If you have already loaded our universal JS tag and want to fire a singular page view event, you can use the following code.
                See our{' '}
                <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for further methods/arguments.
              </p>
              <CodeSnippet codeType="genericViewSingle" />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: Default Tracking Parameters">
            <FFCol gap="12px">
              <p>
                This code is optional and is NOT required for tracking to work. If you would like to track organic visits to your page, it
                needs to declare some defaults. You can embed the below code <em>before</em> our global snippet, to provide the tracking
                with default parameters. See our{' '}
                <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet codeType="defaultParams" idPage={getValues('idPage')} />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: View-only Event">
            <FFCol gap="12px">
              <p>
                If you have already loaded our global JS code and want to fire a singular page view event, you can use the following code.
                If you want to force the page ID for the view, you can pass this as an additional argument (see our help documentation).
              </p>
              <CodeSnippet codeType="genericViewSingle" maxContent />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'conversionTracking'} beRenderedAlways>
        <FFCol gap="18px" height="max-content">
          <SectionBox title="Postback URL Tracking">
            <FFRow marginBottom="18px">
              <FFField label="Select a domain:" block>
                <FFSelect
                  options={domains}
                  valueGetter={(opt) => opt.domain}
                  labelGetter={(opt) => opt.domain}
                  value={domain}
                  onSelect={setDomain}
                  placeholder="Notes"
                />
              </FFField>
            </FFRow>
            <FFField
              label={`Default Postback URL for ${offerSource?.offerSourceName || 'Offer'}`}
              tooltipContent="Here we provide the default postback URL/Pixel tracking code for this offer. These values are based on what you have set in your offer source. They are here just to make adding codes to your page or offer platform easier."
            >
              <CodeSnippet
                codeType="offerPostbackUrl"
                domain={domain}
                postbackPayout={rev}
                postbackTxId={tx}
                postbackSubId={hit}
                idPage={getValues('idPage')}
                placeholder="Default Postback URL"
                data-testid="postbackUrl"
              />
            </FFField>
          </SectionBox>
          <SectionBox title="Universal Javascript Tag (conversion)">
            <FFCol gap="12px">
              <p>
                Here is the full Javascript code to place at this offer to track a conversion. It includes our universal JS tag and a
                conversion event. See our{' '}
                <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet
                codeType="offerConversionFull"
                domain={domain}
                postbackPayout={rev}
                postbackTxId={tx}
                idPage={getValues('idPage')}
                data-testid="conversionJsFull"
              />
            </FFCol>
          </SectionBox>
          <SectionBox title="Conversion-only Event">
            <FFCol gap="12px">
              <p>
                If you have already loaded our universal JS tag and tracked an offer page view, you can fire a conversion event on its own
                with the following code. See our{' '}
                <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet
                codeType="offerConversionSingle"
                domain={domain}
                postbackPayout={rev}
                postbackTxId={tx}
                idPage={getValues('idPage')}
                data-testid="conversionJsSingle"
              />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'help'}>
        <SectionBox title="Help">
          <FFCol gap="12px">
            <p>Offers are pages you send users to within your funnels. These pages can convert and create revenue, unlike landers.</p>
            <p>
              When creating offers you can provide an Offer Source, which helps group the offers by source but also lets them inherit the
              tracking template from your Offer Source.
            </p>
            <p>
              Typically, offers will be from a third-party using their own tracking system, so here you can add a base URL then template how
              you pass additional data.
            </p>
            <p>The conversion tracking info here is from the Offer's Source and is shown for convenience.</p>
            <p>
              For more help on offers, see our documentation{' '}
              <a href="https://help.funnelflux.pro/article/6-introduction-to-offers" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
            <p>
              For further information on using our Javascript tracking, see our documentation{' '}
              <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
    </>
  );
});

const OfferForm = () => {
  const isOpen = useFormStore((state) => state.offer.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const closeForm = useFormStore((state) => state.closeOfferForm);
  const [currentTabId, setCurrentTabId] = useState<TabId>('general');
  const offerFormProps = useFormStore((state) => state.offer);
  const { data: offer = DEFAULT_OFFER, isFetching } = useOfferQuery(offerFormProps?.data?.id!);
  const formRef = useRef<RefType>({
    onSave: () => {},
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  const defaultFormValues = useMemo(() => {
    if (offerFormProps.isDuplication) {
      return {
        ...offer,
        pageName: addOrIncrementCopySuffix(offer.pageName),
      };
    }
    return offer;
  }, [offer, offerFormProps.isDuplication]);

  const copyItems: SidebarCopyItems[] = useMemo(() => {
    if (offerFormProps.data?.id) {
      return [{ title: 'Offer ID', value: offerFormProps.data.id }];
    }
    return [];
  }, [offerFormProps.data?.id]);

  const onClose = () => {
    setCurrentTabId('general');
    closeForm();
  };

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={600}
      maxWidth={1100}
      tabs={tabs}
      copyItems={copyItems}
      onClose={onClose}
      sidebarName="OfferForm"
      currentTabId={currentTabId}
      offsetLevel={getSidebarOffsetLevel(openedForms, 'offer')}
      zIndex={getSidebarZIndex(openedForms, 'offer')}
      title={offerFormProps.isDuplication ? 'Duplicate Offer' : offerFormProps.data?.id ? 'Edit Offer' : 'Create Offer'}
      setCurrentTabId={(tabId) => setCurrentTabId(tabId as TabId)}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {isFetching ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <Form
          defaultFormValues={defaultFormValues}
          currentTabId={currentTabId}
          closeForm={onClose}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default OfferForm;
