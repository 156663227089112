import { Suspense, useMemo } from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { FFNewIcon, FFRow } from '@/uikit';
import VersioningForm from '@/components/Forms/Versioning';
import CategoryForm from '@/components/Forms/Category';
import FunnelForm from '@/components/Forms/Funnel';
import FunnelGroupForm from '@/components/Forms/FunnelGroup';
import SimpleFlowForm from '@/components/Forms/SimpleFlow';
import TrafficSourceForm from '@/components/Forms/TrafficSource';
import PageGroupForm from '@/components/Forms/PageGroup';
import LanderForm from '@/components/Forms/Lander';
import OfferForm from '@/components/Forms/Offer';
import OfferSourceForm from '@/components/Forms/OfferSource';
import ConditionForm from '@/components/Forms/Condition';
import CreatePageGroupForm from '@/components/CreatePageGroupModal';
import { useSystemSettingsQuery } from '@/api/queries/systemSettings';
import { useDomainsQuery } from '@/api/queries/domains';
import { useUserQuery } from '@/api/queries/user';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import useSystemSettingsStore from '@/stores/systemSettings';
import { Helmet } from 'react-helmet-async';
import { isJustExpired, isJustStandBy, loadGistAndSegmentScript } from '@/utils/app';
import useUserStore from '@/stores/user';
import ErrorFallback from '@/components/ErrorFallback';
import { message } from 'antd';
import { identifyCanny } from '@/widgets/canny';

const { UpdateRoutes } = lazyImport(() => import('@/features/updates'), 'UpdateRoutes');
const { SettingsRoutes } = lazyImport(() => import('@/features/settings'), 'SettingsRoutes');
const { PagesAndResourcesRoutes } = lazyImport(() => import('@/features/pagesAndResources'), 'PagesAndResourcesRoutes');
const { LabsRoutes } = lazyImport(() => import('@/features/labs'), 'LabsRoutes');
const { DashboardRoutes } = lazyImport(() => import('@/features/dashboard'), 'DashboardRoutes');
const { FunnelRoutes } = lazyImport(() => import('@/features/funnels'), 'FunnelRoutes');
const { ReportingRoutes } = lazyImport(() => import('@/features/reporting'), 'ReportingRoutes');

const Loading = (
  <FFRow height="100vh" alignItems="center" justifyContent="center">
    <FFNewIcon name="general/app-loading" width={100} height={100} />
  </FFRow>
);

const messageKey = 'subscription-status';

const App = () => {
  const setSystemColorMode = useSystemSettingsStore((state) => state.setSystemColorMode);
  const user = useUserStore(state => state.user);
  const subscriptionStatus = useUserStore(state => state.subscriptionStatus);
  const colorMode = useSystemSettingsStore((state) => state.colorMode);
  const { isLoading: isSystemSettingsLoading } = useSystemSettingsQuery();
  const { isLoading: isDomainsLoading } = useDomainsQuery();
  const { isLoading: isUserLoading } = useUserQuery();
  const isLoading = isSystemSettingsLoading || isDomainsLoading || isUserLoading;

  const justStandBy = useMemo(() => isJustStandBy(subscriptionStatus), [subscriptionStatus]);
  const justExpired = useMemo(() => isJustExpired(subscriptionStatus), [subscriptionStatus]);

  useEffect(() => {
    if (justExpired) {
      message.open({
        key: messageKey,
        content: 'Your subscription is inactive. Note expired subscriptions have data removed after 60 days.',
        duration: 0,
        type: 'warning',
      });
    } else if (justStandBy) {
      message.open({
        key: messageKey,
        content: 'You are currently on the Standby plan. Your data will not be deleted, but UI access is limited.',
        duration: 0,
        type: 'warning'
      });
    }
    return () => {
      message.destroy(messageKey);
    };
  }, [justExpired, justStandBy]);

  useEffect(() => {
    inject();
    injectSpeedInsights();
    setSystemColorMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      setSystemColorMode(event.matches ? 'dark' : 'light');
    });
  }, []);

  useEffect(() => {
    if (user.user_id) {
      loadGistAndSegmentScript(user);
      identifyCanny(user);
    }
  }, [user?.user_id]);

  return (
    <>
      <Helmet>
        <html className={`${colorMode}-mode`} />
      </Helmet>
      <VersioningForm />
      <CategoryForm />
      <FunnelForm />
      <FunnelGroupForm />
      <SimpleFlowForm />
      <TrafficSourceForm />
      <PageGroupForm />
      <LanderForm />
      <OfferForm />
      <OfferSourceForm />
      <ConditionForm />
      <CreatePageGroupForm />
      <Layout>
        <Suspense fallback={Loading}>{isLoading ? Loading : <Outlet />}</Suspense>
      </Layout>
    </>
  );
};

export const protectedRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorFallback />,
    children: [
      { path: '/pages-and-resources/*', element: <PagesAndResourcesRoutes /> },
      { path: '/labs/*', element: <LabsRoutes /> },
      { path: '/updates/*', element: <UpdateRoutes /> },
      { path: '/settings/*', element: <SettingsRoutes /> },
      { path: '/dashboard/*', element: <DashboardRoutes /> },
      { path: '/funnels/*', element: <FunnelRoutes /> },
      { path: '/reporting/*', element: <ReportingRoutes /> },
      { index: true, element: <Navigate to="/dashboard/summary" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard/summary" />,
  },
]);
