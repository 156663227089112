import React from 'react';
import ReactDOM from 'react-dom/client';
import '@ant-design/v5-patch-for-react-19';
import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-community';
import { LICENSE_KEY } from './constants/table';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const packageJson = require('../package.json');

serviceWorkerRegistration.register({
  onUpdate: (registration) => {}
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: [
  //   /^https:\/\/api\.funnelflux\.pro/,
  //   /^https:\/\/middleware\.funnelflux\.pro/,
  //   /^https:\/\/api-staging\.funnelflux\.pro/,
  // ],
  release: 'funnelflux@' + packageJson.version || 'notSetted',
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

declare global {
  interface Window {
    Chargebee: any;
  }
}

if (LICENSE_KEY) {
  ModuleRegistry.registerModules([AllEnterpriseModule.with(AgChartsCommunityModule)]);
  LicenseManager.setLicenseKey(LICENSE_KEY);
} else {
  throw Error('Provide key');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
