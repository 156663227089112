import { FFButton, FFInput, FFRow, FFSidePanel } from '@/uikit';
import className from '@/utils/className';
import { partnerCategories, partners, PartnerCategory, Partner } from './constants';
import ImageAsyncSrc from '../ImageAsyncSrc';
import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import './style.scss';

const { blockClassName, getClass } = className('c-partners');

interface ContentProps {
  onSelectPartner: (partner: Partner) => void;
  onClose: () => void;
}

const Content = ({ onSelectPartner, onClose }: ContentProps) => {
  const [category, setCategory] = useState<PartnerCategory>('Communities');

  const filteredPartners = partners.filter((partner) => {
    return partner.category === category && partner.partner_name.toLowerCase()
  });

  return (
    <div className={blockClassName}>
      <div className={getClass('header')}>
        <div className={getClass('categories')}>CATEGORIES</div>
        <div className={getClass('partnersMarketplace')}>
          <FFRow alignItems="center" gap="8px">
            <ImageAsyncSrc src="funnelflux-symbol.svg" className={getClass('partnersMarketplaceLogo')} alt="Partners Marketplace" />
            <h3>Partners Marketplace</h3>
          </FFRow>
          <FFRow alignItems="center" justifyContent="space-between" gap="8px">
            <FFButton onClick={onClose}>Close</FFButton>
          </FFRow>
        </div>
      </div>
      <div className={getClass('body')}>
        <div className={getClass('categoriesList')}>
          {partnerCategories.map((c) => (
            <div key={c} className={getClass('categoryListItem', { active: c === category })} onClick={() => setCategory(c)}>
              {c}
            </div>
          ))}
        </div>
        <div className={getClass('partnersList')}>
          {filteredPartners.map((partner) => (
            <div key={partner.key} className={getClass('partnerListItem')} onClick={() => onSelectPartner(partner)}>
              <div className={getClass('partnerListItemImage')}>
                <ImageAsyncSrc src={partner.img_src} alt={partner.partner_name} />
              </div>
              <div className={getClass('partnerListItemContent')}>
                <div className={getClass('partnerListItemTitle')}>{partner.partner_name}</div>
                <ReactMarkdown className={getClass('partnerListItemDescription')}>{partner.description}</ReactMarkdown>
                <div className={getClass('partnerListItemPromoDescription')}>{partner.promo_description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const DescriptionContent = ({ partner }: { partner: Partner }) => {
  return (
    <div className={getClass('descriptionContent')}>
      <div className={getClass('descriptionContentHeader')}>
        <span className={getClass('descriptionContentHeaderTitle')}>{partner.partner_name}</span>
      </div>
      <div className={getClass('descriptionContentImage')}>
        <ImageAsyncSrc src={partner.img_src} alt={partner.partner_name} />
      </div>
      <ReactMarkdown className={getClass('descriptionContentText')}>{partner.description_expanded}</ReactMarkdown>
      <a href={partner.cta_link} className={getClass('descriptionContentCta')} target="_blank" rel="noopener noreferrer">
        <FFButton size="large">{partner.cta_text}</FFButton>
      </a>
    </div>
  );
};

const Partners = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [isDescriptionSidePanelOpen, setIsDescriptionSidePanelOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Partner>(partners[0]);

  const onSelectPartner = (partner: Partner) => {
    setSelectedPartner(partner);
    setIsDescriptionSidePanelOpen(true);
  };

  return (
    <>
      <FFSidePanel
        headerClassName={getClass('sidebarHeader')}
        contentClassName={getClass('sidebarContent')}
        padding={0}
        zIndex={1001}
        maxWidth={500}
        closableByOverlay
        sidebarName="PartnersDescription"
        title=""
        isOpen={isDescriptionSidePanelOpen}
        onClose={() => setIsDescriptionSidePanelOpen(false)}
      >
        {isDescriptionSidePanelOpen && <DescriptionContent partner={selectedPartner} />}
      </FFSidePanel>
      <FFSidePanel
        headerClassName={getClass('sidebarHeader')}
        contentClassName={getClass('sidebarContent')}
        padding={0}
        zIndex={1000}
        maxWidth={1000}
        closableByOverlay
        sidebarName="Partners"
        title="Partners"
        isOpen={isOpen}
        onClose={onClose}
      >
        {isOpen && <Content onSelectPartner={onSelectPartner} onClose={onClose} />}
      </FFSidePanel>
    </>
  );
};

export default Partners;
