import { useFunnelGroupListQuery, useFunnelQuery } from '@/api/queries/funnel';
import { message as AntMessage } from 'antd';
import SectionBox from '@/components/SectionBox';
import useFormStore from '@/stores/forms';
import useSystemSettingsStore from '@/stores/systemSettings';
import { CommonFormProps } from '@/types/forms/form';
import {
  SimpleFlowFormRefType,
  SimpleFlowFormTabId,
  SimpleFlowPathEditorFormRefType,
  SimpleFlowRuleEditorFormRefType,
} from '@/types/forms/simpleFlow';
import Skeleton from 'react-loading-skeleton';
import {
  FFAddGroup,
  FFButton,
  FFCol,
  FFField,
  FFIconButton,
  FFInput,
  FFNewIcon,
  FFRow,
  FFSelect,
  FFSidePanel,
  VisibilityWrapper,
} from '@/uikit';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { removeNonDigits, trimStringPropertiesInObject, withoutWhiteSpace } from '@/utils/string';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import className from '@/utils/className';
import { SimpleFlow as SimpleFlowType } from '@/models/simpleFlow';
import { generateEntityId } from '@/utils/id';
import PathEditor from './components/PathEditor';
import useMitt from '@/hooks/mitt';
import RuleEditor from './components/RuleEditor';
import { SimpleFlowRule } from '@/models/simpleFlowRules';
import { SimpleFlowPath } from '@/models/simpleFlowPath';
import EntrySlider from './components/EntrySlider';
import { actionNumbers, IPs } from '@/constants';
import CodeSnippet from '@/components/CodeSnippet';
import { getPageActionLink } from '@/utils/linkJs';
import { FFSelectOption } from '@/uikit/types/select';
import { useTrafficSourcesCategoryListQuery } from '@/api/queries/trafficSource';
import RedirectLinks from './components/RedirectLinks';
import { Funnel } from '@/models/funnel';
import { FunnelNode } from '@/models/funnelNode';
import { defined } from '@/utils/define';
import { moveToFirstByFunc } from '@/utils/sort';
import { usePageGroupListQuery } from '@/api/queries/pageGroup';
import { PageGroupInfo } from '@/models/pageGroupInfo';
import { usePageGroupCreateMutation, usePageGroupUpdateMutation } from '@/api/mutations/pageGroup';
import { useConditionCreateMutation, useConditionUpdateMutation } from '@/api/mutations/condition';
import { useConditionQuery } from '@/api/queries/condition';
import { getFunnelNodesConnections } from './utils';
import { withIncrementedVersion } from '@/utils/model';
import { useFunnelCreateMutation, useFunnelDuplicateMutation, useFunnelUpdateMutation } from '@/api/mutations/funnel';
import useHttp from '@/hooks/http';
import { getTrafficNode } from '@/constants/builder';
import { FunnelCondition } from '@/models/funnelCondition';
import './style.scss';

const { getClass } = className('c-simpleFlowForm');

const DefaultCostTooltip = () => (
  <>
    <p>
      If you add a value here, all funnels in this group will by default inherit this when generating links, unless a cost is added at a
      more specific level. A cost is assigned to every visitor who enters a funnel (basically a cost per click).
    </p>
    ,<p>When generating links for your funnel, we check for default costs in the following order:</p>,
    <ul>
      <li>Funnel Group (can only add a cost value)</li>
      <li>Funnel (can only add a cost value)</li>
      <li>Traffic Source (can use values or tokens/text)</li>
      <li>Cost field in the link generation form</li>
    </ul>
    ,<p>These parameters are appended to URLs with …&c=X</p>
  </>
);

const DEFAULT_SIMPLEFLOW: SimpleFlowType = {
  idFlow: '',
  idFunnelGroup: '',
  flowName: '',
  defaultCost: '',
  paths: {},
  rules: {},
  routings: {},
};

const DEFAULT_FUNNEL: Funnel = {
  idCampaign: '',
  idFunnel: '',
  funnelName: '',
};

const tabs: SidebarTab<SimpleFlowFormTabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Paths',
    tabId: 'configure-paths',
    icon: <FFNewIcon name="sidebar-tabs/configure-paths" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Rules',
    tabId: 'configure-rules',
    icon: <FFNewIcon name="sidebar-tabs/configure-rules" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Routing',
    tabId: 'configure-routing',
    icon: <FFNewIcon name="sidebar-tabs/configure-routing" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Get Redirect Links',
    tabId: 'redirect-links',
    icon: <FFNewIcon name="sidebar-tabs/redirect-links" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Page Action Links',
    tabId: 'page-action-links',
    icon: <FFNewIcon name="sidebar-tabs/page-action-link" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Advanced Flow Settings',
    tabId: 'advanced-flow-settings',
    icon: <FFNewIcon name="sidebar-tabs/advanced-flow-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" display="inline-block" type="sidebartab" />,
  },
];

interface IncomingCostOverride {
  key: string;
  value: string;
}

interface FormProps extends CommonFormProps<SimpleFlowType, SimpleFlowFormTabId> {
  defaultFunnel: Funnel | null;
  setAllPaths: (paths: SimpleFlowPath[]) => void;
  setAllRules: (rules: SimpleFlowRule[]) => void;
}

const Form = forwardRef(
  (
    { currentTabId, closeForm, setSubmitLoading, setAllPaths, setAllRules, submitLoading, defaultValues, defaultFunnel }: FormProps,
    ref: Ref<SimpleFlowFormRefType>,
  ) => {
    const { get: httpGet } = useHttp();
    const emitter = useMitt();

    const conditionId = useMemo(() => {
      return (defaultFunnel?.nodes || []).find((node) => node.nodeType === 'condition')?.nodeConditionParams?.idCondition;
    }, [defaultFunnel]);

    const { data: funnelGroupList = [] } = useFunnelGroupListQuery();
    const { data: landerPageGroupList } = usePageGroupListQuery('lander', 'active', false, defaultFunnel?.idFunnel!);
    const { data: offerPageGroupList } = usePageGroupListQuery('offer', 'active', false, defaultFunnel?.idFunnel!);
    const { data: trafficSourceCategoryList = [] } = useTrafficSourcesCategoryListQuery();
    const { data: condition } = useConditionQuery(conditionId || '');
    const { mutateAsync: createPageGroup } = usePageGroupCreateMutation();
    const { mutateAsync: updatePageGroup } = usePageGroupUpdateMutation();
    const { mutateAsync: updateCondition } = useConditionUpdateMutation();
    const { mutateAsync: createCondition } = useConditionCreateMutation();
    const { mutateAsync: createFunnel } = useFunnelCreateMutation();
    const { mutateAsync: updateFunnel } = useFunnelUpdateMutation();
    const { mutateAsync: duplicateFunnel } = useFunnelDuplicateMutation(defaultFunnel?.idFunnel || '');

    const openVersioningForm = useFormStore((state) => state.openVersioningForm);
    const setVersioningType = useFormStore((state) => state.setVersioningType);
    const openSimpleFlowPathEditorForm = useFormStore((state) => state.openSimpleFlowPathEditorForm);
    const openSimpleFlowRuleEditorForm = useFormStore((state) => state.openSimpleFlowRuleEditorForm);
    const domains = useSystemSettingsStore((state) => state.domains);
    const isDuplication = useFormStore((state) => state.simpleFlow.isDuplication);
    const defaultCustomDomain = useSystemSettingsStore((state) => state.userSettings.defaultCustomDomain);
    const systemDefaultIpAnonymizer = useSystemSettingsStore((state) => state.userSettings.ipAnonymizer);

    const [domain, setDomain] = useState(defaultCustomDomain);
    const [actionNumber, setActionNumber] = useState<string>('');
    const [ipAnonymizer, setIpAnonymizer] = useState(systemDefaultIpAnonymizer);
    const [updatedGroupIds, setUpdatedGroupIds] = useState<string[]>([]);
    const [incomingCostOverrides, setIncomingCostOverrides] = useState<IncomingCostOverride[]>([{ key: '', value: '' }]);
    const [selectedRoutingRuleId, setSelectedRoutingRuleId] = useState<string>('');
    const [latestVersionOfFunnel, setLatestVersionOfFunnel] = useState<Funnel>();

    const {
      handleSubmit,
      control,
      formState: { errors },
      watch,
      setValue,
    } = useForm<SimpleFlowType>({
      defaultValues,
    });

    const flowId = watch('idFlow');
    const paths = watch('paths');
    const rules = watch('rules');
    const routings = watch('routings');

    const isNewFlow = useMemo(() => !defaultFunnel?.idFunnel || defaultFunnel?.idFunnel?.length === 0, [defaultFunnel]);
    const pathsArray = useMemo(() => Object.values(paths || []), [paths]);
    const rulesArray = useMemo(() => Object.values(rules || []), [rules]);

    const routingEntries = useMemo(() => {
      return routings?.[selectedRoutingRuleId] || [];
    }, [routings, selectedRoutingRuleId]);

    const totalWeight = useMemo(() => routingEntries.reduce((acc, routeEntry) => acc + routeEntry.weight, 0), [routingEntries]);

    const trafficSourceOptions = useMemo(() => {
      const options: FFSelectOption[] = [];
      trafficSourceCategoryList.forEach((category) => {
        (category.trafficSources || []).forEach((trafficSource) => {
          options.push({ value: trafficSource.idTrafficSource, label: trafficSource.trafficSourceName, category: category.categoryName });
        });
      });
      return options;
    }, [trafficSourceCategoryList]);

    const pageGroupsMap = useMemo(() => {
      const map = new Map<string, PageGroupInfo<'lander'> | PageGroupInfo<'offer'>>();
      (landerPageGroupList || []).forEach((pageGroup) => {
        map.set(pageGroup.idPageGroup, pageGroup);
      });
      (offerPageGroupList || []).forEach((pageGroup) => {
        map.set(pageGroup.idPageGroup, pageGroup);
      });
      return map;
    }, [landerPageGroupList, offerPageGroupList]);

    const funnelNodes = useMemo(() => {
      const sortedNodes: FunnelNode[] = [];
      const trafficNode = (defaultFunnel?.nodes || []).find((funnelNode) => funnelNode.nodeType === 'root');
      sortedNodes.push({
        ...trafficNode!,
        nodeName: 'Start of this flow',
      });
      (defaultFunnel?.nodes || []).forEach((funnelNode) => {
        if (funnelNode.nodeType === 'rotator' && defined(paths[funnelNode.idNode])) {
          sortedNodes.push(funnelNode);
        }
      });
      return sortedNodes;
    }, [defaultFunnel, paths]);

    useEffect(() => {
      initializePathsAndRules();
      setAllPaths(pathsArray);
      setAllRules(rulesArray);
      if (Object.keys(defaultFunnel?.incomingCostOverrides || {}).length > 0) {
        setIncomingCostOverrides(Object.entries(defaultFunnel?.incomingCostOverrides || {}).map(([key, value]) => ({ key, value })));
      }

      emitter.on('onSimpleFlowPathSave', (event) => {
        setValue('paths', {
          ...paths,
          [event.path.pathId]: event.path,
        });
        setAllPaths([...pathsArray, event.path]);
        setUpdatedGroupIds(event.updatedGroupIds);
      });
      emitter.on('onSimpleFlowRuleSave', (rule) => {
        setValue('rules', {
          ...rules,
          [rule.ruleId]: rule,
        });
        setAllRules([...rulesArray, rule]);
      });
      emitter.on('onVersioningConfirm', () => {
        onSave(true);
      });
    }, [pathsArray, rulesArray, paths, rules]);

    const onEditRule = (rule: SimpleFlowRule) => {
      openSimpleFlowRuleEditorForm(rule);
    };

    const initializePathsAndRules = () => {
      const pathId = generateEntityId();
      const defeaultPath: SimpleFlowPath = {
        pathId,
        pathName: 'Default Path',
        groups: [],
        isDefault: true,
      };
      const ruleId = generateEntityId();
      const defaultRule: SimpleFlowRule = {
        ruleId,
        ruleName: 'Default (all unmatched traffic)',
        isDefault: true,
        rule: {
          operator: 'or',
          groups: [],
          routeName: 'Default Route',
        },
      };

      if (!rulesArray.length) {
        setValue('rules', {
          ...rules,
          [ruleId]: defaultRule,
        });
      }
      if (!pathsArray.length) {
        setValue('paths', {
          ...paths,
          [pathId]: defeaultPath,
        });
      }
      if (!Object.keys(routings).length) {
        setValue('routings', {
          ...routings,
          [ruleId]: [{ weight: 1, path: defeaultPath }],
        });
      }

      setSelectedRoutingRuleId(rulesArray[0]?.ruleId || ruleId);
    };

    const onAddNewPath = () => {
      openSimpleFlowPathEditorForm({
        pathId: generateEntityId(),
        pathName: 'New Path',
        groups: [],
        isDefault: false,
      });
    };

    const onAddNewRule = () => {
      openSimpleFlowRuleEditorForm({
        ruleId: generateEntityId(),
        ruleName: 'New Rule',
        rule: {
          routeName: '',
          operator: 'or',
          groups: [
            {
              operator: 'or',
              rules: [
                {
                  attribute: '' as any,
                  test: '' as any,
                },
              ],
            },
          ],
        },
        isDefault: false,
      });
    };

    const onSelectRoutingPath = (pathId: string) => {
      const pathIdsOfSelectedRule = routingEntries.map((routeEntry) => routeEntry.path.pathId);
      if (pathIdsOfSelectedRule.includes(pathId)) return false;

      setValue('routings', {
        ...routings,
        [selectedRoutingRuleId]: [
          ...(routings?.[selectedRoutingRuleId] || []),
          {
            weight: 1,
            path: paths[pathId],
          },
        ],
      });
    };

    const onRoutingEntryChange = (routeEntryId: string, weight: number) => {
      setValue('routings', {
        ...routings,
        [selectedRoutingRuleId]: routingEntries.map((routeEntry) =>
          routeEntry.path.pathId === routeEntryId ? { ...routeEntry, weight } : routeEntry,
        ),
      });
    };

    const onAddIncomingCostOverride = () => {
      setIncomingCostOverrides([...incomingCostOverrides, { key: '', value: '' }]);
    };

    const onIncomingCostOverrideChange = (key: 'key' | 'value', value: string, idx: number) => {
      setIncomingCostOverrides(
        incomingCostOverrides.map((incomingCostOverride, _idx) =>
          _idx === idx ? { ...incomingCostOverride, [key]: value } : incomingCostOverride,
        ),
      );
    };

    const onDeleteRoutingEntry = (routeEntryId: string) => {
      setValue('routings', {
        ...routings,
        [selectedRoutingRuleId]: routingEntries.filter((routeEntry) => routeEntry.path.pathId !== routeEntryId),
      });
    };

    const createUpdatePageGroups = async (funnel: Funnel) => {
      for (const path of Object.values(funnel.meta?.simpleFlow?.paths || {})) {
        for (const group of path.groups) {
          if (!pageGroupsMap.has(group.idPageGroup)) {
            await createPageGroup({
              ...group,
              restrictToFunnelId: funnel.idFunnel,
            });
          } else {
            if (updatedGroupIds.includes(group.idPageGroup)) {
              await updatePageGroup({
                ...group,
                restrictToFunnelId: funnel.idFunnel,
              });
            }
          }
        }
      }
    };

    const onClose = () => {
      emitter.all.clear();
      closeForm();
    };

    const getProcessedModel = async (funnel: Funnel, condition: FunnelCondition) => {
      await createUpdatePageGroups(funnel);
      if (condition) {
        await updateCondition(condition);
      }
      return getFunnelNodesConnections(funnel, condition, rulesArray, pathsArray, routings);
    };

    const onSave = (isVersioning = false) =>
      handleSubmit(async (data) => {
        for (const pathId in data.paths) {
          if (!data.paths[pathId]?.groups.length) {
            AntMessage.warning(`The path "${data.paths[pathId].pathName}" should have at least one page group`);
            return false;
          }
        }
        for (const ruleId in data.rules) {
          if (!defined(data.routings[ruleId]) || !data.routings[ruleId].length) {
            AntMessage.warning('All rules must have at least one destination path configured');
            return false;
          }
        }

        const newID = generateEntityId();
        let funnelModel: Funnel = {
          ...defaultFunnel,
          funnelName: data.flowName,
          idCampaign: data.idFunnelGroup,
          idFunnel: defaultFunnel?.idFunnel || newID,
          defaultCost: data.defaultCost ? data.defaultCost : undefined,
          incomingCostOverrides: incomingCostOverrides.reduce((acc: { [key: string]: string }, crr) => {
            if (crr.key && crr.value) {
              acc[crr.key] = crr.value;
            }
            return acc;
          }, {}),
          funnelType: 'flow',
          meta: {
            ...(defaultFunnel?.meta || {}),
            isFlow: true,
            simpleFlow: data,
          },
        };
        const conditionModel: FunnelCondition = {
          ...condition,
          restrictToFunnelId: funnelModel.idFunnel,
          idCondition: condition?.idCondition || generateEntityId(),
          conditionName: 'Dynamic Routing by Rules',
          routes: moveToFirstByFunc(
            Object.values(data.rules).map((rule) => rule.rule),
            (rule) => rule.routeName === 'Default Route',
          ),
        };

        setSubmitLoading(true);
        funnelModel = trimStringPropertiesInObject<Funnel>(funnelModel, ['funnelName']);
        const updateModel = withIncrementedVersion(funnelModel);
        const duplicateModel: Funnel = { ...funnelModel, meta: { ...funnelModel.meta, version: 1 } };
        const createModel: Funnel = {
          ...funnelModel,
          idFunnel: newID,
          nodes: [getTrafficNode()],
          meta: { ...funnelModel.meta, version: 1 },
        };
        const funnelGroup = funnelGroupList.find((funnelGroup) => funnelGroup.idCampaign === funnelModel.idCampaign)!;

        if (isVersioning) {
          try {
            const versionedModel = withIncrementedVersion(updateModel, latestVersionOfFunnel?.meta?.version);
            const processedModel = await getProcessedModel(versionedModel, conditionModel);
            await updateFunnel(processedModel);
            emitter.emit('onFunnelUpdate', processedModel);
            onClose();
          } catch (e) {
            //
          } finally {
            setSubmitLoading(false);
          }
        } else {
          try {
            if (isDuplication) {
              const processedModel = await getProcessedModel(duplicateModel, conditionModel);
              const duplicatedFunnel = await duplicateFunnel(processedModel);
              emitter.emit('onFunnelCreate', { data: duplicatedFunnel, funnelGroup });
            } else if (!isNewFlow) {
              const latestFunnel = await httpGet<Funnel>('v1/campaign/funnel/find/byId', {
                params: { idFunnel: funnelModel.idFunnel },
              });
              setLatestVersionOfFunnel(latestFunnel.data);
              if (funnelModel.meta?.version !== latestFunnel.data.meta?.version) {
                setVersioningType('funnel');
                openVersioningForm();
                return;
              } else {
                const processedModel = await getProcessedModel(updateModel, conditionModel);
                await updateFunnel(processedModel);
                emitter.emit('onFunnelUpdate', processedModel);
              }
            } else {
              await createFunnel(createModel);
              await createCondition({
                ...conditionModel,
                status: 'active',
              });
              await createUpdatePageGroups(createModel);
              const processedModel = getFunnelNodesConnections(
                createModel,
                conditionModel,
                Object.values(data.rules),
                Object.values(data.paths),
                routings,
              );
              await updateFunnel(processedModel);
              emitter.emit('onFunnelCreate', { data: processedModel, funnelGroup });
            }

            onClose();
          } catch (e) {
            //
          } finally {
            setSubmitLoading(false);
          }
        }
      })();

    useImperativeHandle(ref, () => ({ onSave }));

    return (
      <>
        <VisibilityWrapper visible={currentTabId === 'general'} beRenderedAlways>
          <FFCol gap="12px">
            <SectionBox title="What are Simple Flows?">
              <p>
                Simple flows allow you to create sequences of pages (a path) and then define rules for how traffic should be routed to
                these.
              </p>
              <p>
                Get started in the paths section by creating a sequence of pages. Then go to the routing section and set the default route
                to go to your path newly created path. You can add custom rules and paths later.
              </p>
            </SectionBox>
            <SectionBox title="General Settings">
              <FFCol gap="8px">
                <Controller
                  name="idFunnelGroup"
                  control={control}
                  rules={{ required: 'Funnel Group is required' }}
                  render={(opt) => (
                    <FFField label="Parent Funnel Group" block>
                      <FFSelect
                        options={funnelGroupList}
                        valueGetter={(opt) => opt.idCampaign}
                        labelGetter={(opt) => opt.campaignName}
                        error={errors.flowName?.message}
                        value={opt.field.value}
                        showSearch
                        onChange={opt.field.onChange}
                        placeholder="Parent Funnel Group"
                      />
                    </FFField>
                  )}
                />
                <Controller
                  name="flowName"
                  control={control}
                  rules={{ required: 'Simple Flow name is required' }}
                  render={(opt) => (
                    <FFField label="Simple Flow Name" block>
                      <FFInput
                        value={opt.field.value}
                        onChange={opt.field.onChange}
                        error={errors.flowName?.message}
                        placeholder="Funnel Name"
                      />
                    </FFField>
                  )}
                />
              </FFCol>
            </SectionBox>
            <SectionBox title="Optional Settings">
              <FFCol gap="12px">
                <Controller
                  control={control}
                  name="defaultCost"
                  render={(opt) => (
                    <FFField label="Default Cost per Entrance" block tooltipContent={<DefaultCostTooltip />}>
                      <FFInput
                        value={opt.field.value}
                        onChange={(e) => opt.field.onChange(withoutWhiteSpace(removeNonDigits(e.target.value)))}
                        placeholder="E.g. 0.01"
                      />
                    </FFField>
                  )}
                />
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'configure-paths'}>
          <FFCol gap="12px">
            <SectionBox title="Create Your Path Units">
              <p>
                Simple flows allow you to create units called paths, which are a sequence of landers/offers chained together in some way.
                You can create as many paths as you want, of any length. You can later create rules and control what paths users will route
                to.
              </p>
            </SectionBox>
            <SectionBox title="Paths">
              <FFCol gap="8px" marginBottom="12px">
                {pathsArray.map((path) => (
                  <div className={getClass('pathItem')}>
                    <div className={getClass('pathItemName')}>
                      <FFNewIcon name="general/duotone/simple-flow" />
                      {path.pathName}
                    </div>
                    <FFIconButton
                      buttonType="transparent"
                      iconName="general/line/pencil"
                      onClick={() => openSimpleFlowPathEditorForm(path)}
                    />
                  </div>
                ))}
              </FFCol>
              <FFButton type="ghost" className="margin-auto" iconName="general/line/add-circle" onClick={onAddNewPath}>
                Add New Path
              </FFButton>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'configure-rules'}>
          <FFCol gap="12px">
            <SectionBox title="Rules for Routing Visitors">
              <p>
                Rules are conditional logic that process when a visitor first arrives in your flow. These process in order, the user gets
                sent to the first one they match, else the default. The paths they then get sent to are controlled in the routing section.
              </p>
            </SectionBox>
            <SectionBox title="Rules">
              <FFCol gap="8px" marginBottom="12px">
                {rulesArray.map((rule) => (
                  <div className={getClass('pathItem')}>
                    <div className={getClass('pathItemName')}>
                      <FFNewIcon name="navigation/funnels" />
                      {rule.ruleName}
                    </div>
                    {!rule.isDefault && (
                      <FFIconButton
                        buttonType="transparent"
                        iconName="general/line/pencil"
                        onClick={() => onEditRule(rule)}
                      />
                    )}
                  </div>
                ))}
              </FFCol>
              <FFButton type="ghost" className="margin-auto" iconName="general/line/add-circle" onClick={onAddNewRule}>
                Add New Rule
              </FFButton>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'configure-routing'}>
          <FFCol gap="12px" flex="1">
            <SectionBox title="How Rules Should Route to Paths">
              <p>
                Here, select any of your rules (including the default) and you can configure what destination paths you would like the user
                to go to.
              </p>
            </SectionBox>
            <SectionBox>
              <FFCol gap="12px">
                <FFRow alignItems="center" gap="12px">
                  <FFCol maxWidth="180px" width="100%">
                    <FFSelect
                      value={selectedRoutingRuleId}
                      options={rulesArray}
                      valueGetter={(r) => r.ruleId}
                      labelGetter={(r) => r.ruleName}
                      placeholder="Select Rule"
                      onSelect={setSelectedRoutingRuleId}
                    />
                  </FFCol>
                  <FFCol maxWidth="180px" width="100%">
                    <FFSelect
                      options={pathsArray}
                      valueGetter={(p) => p.pathId}
                      labelGetter={(p) => p.pathName}
                      placeholder="Select Path"
                      onSelect={onSelectRoutingPath}
                    />
                  </FFCol>
                </FFRow>
                <FFCol gap="12px">
                  {routingEntries.map((routingEntry) => (
                    <EntrySlider
                      key={routingEntry.path.pathId}
                      sliderEntry={routingEntry}
                      totalWeight={totalWeight}
                      isFlow={true}
                      onChange={onRoutingEntryChange}
                      onDelete={onDeleteRoutingEntry}
                      getId={(e) => e.path.pathId}
                      getName={(e) => e.path.pathName}
                    />
                  ))}
                </FFCol>
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'redirect-links'} beRenderedAlways>
          <RedirectLinks funnelNodes={funnelNodes} flowId={flowId} />
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'page-action-links'}>
          <SectionBox title="Page Action Links">
            <FFCol gap="12px">
              <p>
                Action links are universal click-through URLs that you should use in your pages to send visitors to the next node. You can
                right-click action connections in the funnel builder to get URLs with additional default parameters.
              </p>
              <FFRow alignItems="center" gap="8px">
                <FFField label="Select a domain" block>
                  <FFSelect
                    options={domains}
                    valueGetter={(opt) => opt.domain}
                    labelGetter={(opt) => opt.domain}
                    value={domain}
                    onSelect={setDomain}
                    placeholder="Select a domain"
                  />
                </FFField>
                <FFField label="Action number" block>
                  <FFSelect
                    options={actionNumbers}
                    valueGetter={(opt) => opt.value}
                    labelGetter={(opt) => opt.label}
                    value={actionNumber}
                    onSelect={setActionNumber}
                    placeholder="Action Number"
                  />
                </FFField>
              </FFRow>
              <FFField label="Action URL" block>
                <CodeSnippet
                  data-testid="actionURL"
                  placeholder="Action URL"
                  className="width-full"
                  code={getPageActionLink(domain, actionNumber)}
                />
              </FFField>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'advanced-flow-settings'}>
          <FFCol gap="12px">
            <SectionBox title="IP Anonymization Settings">
              <FFCol gap="18px">
                <p>
                  These settings will determine how FunnelFlux truncates IP data before it gets logged to your analytics database, for user
                  privacy and GDPR compliance. This will not impact geo-location data or tokens used in page URLs.
                </p>
                <FFSelect
                  options={IPs}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => (opt.value === systemDefaultIpAnonymizer ? `System Default (${opt.label})` : opt.label)}
                  value={ipAnonymizer}
                  onSelect={setIpAnonymizer}
                />
              </FFCol>
            </SectionBox>
            <SectionBox title="Incoming Traffic Cost Override">
              <FFCol gap="18px">
                <p>
                  Incoming cost overrides will change the cost of all matching visitors who enter a funnel. This will not affect
                  existing/past visitors. These overrides are higher priority than the "c" parameter in URLs/JS and can be used to update
                  average incoming cost in real-time.
                </p>
                <FFAddGroup
                  rows={incomingCostOverrides}
                  showAddRow={true}
                  onAddRow={onAddIncomingCostOverride}
                  renderRow={(row, rowIdx) => {
                    return (
                      <>
                        <FFAddGroup.Col>
                          <FFSelect
                            options={trafficSourceOptions}
                            valueGetter={(opt) => opt.value}
                            labelGetter={(opt) => opt.label}
                            groupOptions={true}
                            sortGroup
                            selectAll
                            showSearch
                            autoFocus
                            value={row.key}
                            placeholder="Select a traffic source"
                            onSelect={(value) => onIncomingCostOverrideChange('key', value, rowIdx)}
                          />
                        </FFAddGroup.Col>
                        <FFAddGroup.Col>
                          <FFInput
                            name="value"
                            value={row.value}
                            placeholder="E.g. 0.01"
                            onChange={(e) =>
                              onIncomingCostOverrideChange('value', withoutWhiteSpace(removeNonDigits(e.target.value)), rowIdx)
                            }
                          />
                        </FFAddGroup.Col>
                      </>
                    );
                  }}
                />
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'help'}>
          <SectionBox title="Help">
            <FFCol gap="18px">
              <p>
                Simple flows are a different visual UI for building funnels in our system. They use the same backend where a funnel is
                created, with nodes linked together in a specific pattern. It's our way of making a simpler entry point for users.
              </p>
              <p>
                To uses these flows, you need to configure three important elements: your page sequences (paths), optional rules visitors
                can be matched against, and lastly, routing configuration for how users will go to each of your paths.
              </p>
              <p>
                Because flows inherently involve rules and redirection, there is currently no direct linking option. We will add this later
                so that you can choose to direct link to a page in one of your paths. Just keep in mind that this would make all
                rules/routing config irrelevant, as you quite literaly skip past it.
              </p>
              <p>
                In the future we will also provide the option to convert a flow to a visual funnel (irreversibly), so that you can edit and
                extend it in our visual builder.
              </p>
              <p>
                Need more help? Read our documentation{' '}
                <a href="https://help.funnelflux.pro/article/93" target="_blank" rel="noreferrer">
                  here
                </a>
              </p>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
      </>
    );
  },
);

const SimpleFlow = () => {
  const isOpen = useFormStore((state) => state.simpleFlow.isOpen);
  const isPathEditorOpen = useFormStore((state) => state.simpleFlowPathEditor.isOpen);
  const isRuleEditorOpen = useFormStore((state) => state.simpleFlowRuleEditor.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const isDuplication = useFormStore((state) => state.simpleFlow.isDuplication);
  const simpleFlowId = useFormStore((state) => state.simpleFlow.data?.id);
  const closeSimpleFlowForm = useFormStore((state) => state.closeSimpleFlowForm);
  const closeSimpleFlowPathEditorForm = useFormStore((state) => state.closeSimpleFlowPathEditorForm);
  const closeSimpleFlowRuleEditorForm = useFormStore((state) => state.closeSimpleFlowRuleEditorForm);

  const [currentTabId, setCurrentTabId] = useState<SimpleFlowFormTabId>('general');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [allPaths, setAllPaths] = useState<SimpleFlowPath[]>([]);
  const [allRules, setAllRules] = useState<SimpleFlowRule[]>([]);

  const { data: funnel = DEFAULT_FUNNEL, isFetching } = useFunnelQuery(simpleFlowId!);

  const filteredTabs = useMemo(() => {
    return simpleFlowId ? tabs : tabs.map((tab) => (tab.tabId === 'redirect-links' ? { ...tab, disabled: true } : tab));
  }, [simpleFlowId]);

  const copyItems: SidebarCopyItems[] = useMemo(() => {
    if (simpleFlowId) {
      return [{ title: 'Simple Flow ID', value: simpleFlowId }];
    }
    return [];
  }, [simpleFlowId]);

  const simpleFlowFormRef = useRef<SimpleFlowFormRefType>({
    onSave: () => {},
  });

  const simpleFlowPathEditorFormRef = useRef<SimpleFlowPathEditorFormRefType>({
    onSave: () => {},
  });

  const simpleFlowRuleEditorFormRef = useRef<SimpleFlowRuleEditorFormRefType>({
    onSave: () => {},
  });

  const onClose = () => {
    setCurrentTabId('general');
    closeSimpleFlowForm();
  };

  const sidebarTitle = useMemo(() => {
    if (isDuplication) {
      return 'Duplicate Simple Flow';
    } else if (simpleFlowId) {
      return 'Edit Simple Flow';
    } else {
      return 'Create Simple Flow';
    }
  }, [isDuplication, simpleFlowId]);

  const onSimpleFlowSave = () => {
    simpleFlowFormRef.current.onSave();
  };

  const onSavePathEditor = () => {
    simpleFlowPathEditorFormRef.current.onSave();
  };

  const onSaveRuleEditor = () => {
    simpleFlowRuleEditorFormRef.current.onSave();
  };

  const onClosePathEditor = () => {
    closeSimpleFlowPathEditorForm();
  };

  const onCloseRuleEditor = () => {
    closeSimpleFlowRuleEditorForm();
  };

  return (
    <>
      <FFSidePanel
        isOpen={isRuleEditorOpen}
        minWidth={600}
        maxWidth={1100}
        copyItems={copyItems}
        onClose={onCloseRuleEditor}
        sidebarName="SimpleFlowRuleEditor"
        offsetLevel={getSidebarOffsetLevel(openedForms, 'simpleFlowRuleEditor')}
        zIndex={getSidebarZIndex(openedForms, 'simpleFlowRuleEditor')}
        title="Rule Editor"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={onSaveRuleEditor} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" onClick={onCloseRuleEditor}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        {isRuleEditorOpen ? <RuleEditor allRules={allRules} ref={simpleFlowRuleEditorFormRef} /> : null}
      </FFSidePanel>
      <FFSidePanel
        isOpen={isPathEditorOpen}
        minWidth={600}
        maxWidth={1100}
        onClose={onClosePathEditor}
        sidebarName="SimpleFlowPathEditor"
        offsetLevel={getSidebarOffsetLevel(openedForms, 'simpleFlowPathEditor')}
        zIndex={getSidebarZIndex(openedForms, 'simpleFlowPathEditor')}
        title="Path Editor"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={onSavePathEditor} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" onClick={onClosePathEditor}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        {isPathEditorOpen ? <PathEditor allPaths={allPaths} ref={simpleFlowPathEditorFormRef} /> : null}
      </FFSidePanel>
      <FFSidePanel
        isOpen={isOpen}
        minWidth={600}
        maxWidth={1100}
        tabs={filteredTabs}
        onClose={onClose}
        sidebarName="SimpleFlowForm"
        currentTabId={currentTabId}
        offsetLevel={getSidebarOffsetLevel(openedForms, 'simpleFlow')}
        zIndex={getSidebarZIndex(openedForms, 'simpleFlow')}
        title={sidebarTitle}
        setCurrentTabId={(tabId) => setCurrentTabId(tabId as SimpleFlowFormTabId)}
        actions={
          <FFRow gap="8px">
            <FFButton onClick={onSimpleFlowSave} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        {isFetching ? (
          <Skeleton width="100%" height="400px" />
        ) : (
          <Form
            defaultValues={funnel?.meta?.simpleFlow || DEFAULT_SIMPLEFLOW}
            defaultFunnel={funnel}
            currentTabId={currentTabId}
            setAllPaths={setAllPaths}
            setAllRules={setAllRules}
            closeForm={onClose}
            setSubmitLoading={setSubmitLoading}
            submitLoading={submitLoading}
            isDuplication={isDuplication}
            ref={simpleFlowFormRef}
          />
        )}
      </FFSidePanel>
    </>
  );
};

export default SimpleFlow;
